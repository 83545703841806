import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';

const ThankYou = () => {
  return (
    <Layout>
      <Helmet>
        <title>Stanford Sonoma - Thank You page</title>
      </Helmet>
      <div className={'SharedEl__container'}>
        <div className={'Thanks__wrapperYO'}>
          <h1 className={'Thanks__centerText'}>Thank you for your submission !</h1>
          <div className={'Thanks__container'}>
            <a href="/" className={'Thanks__button'}>
              Return to Homepage
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYou;
